import {createWebHistory, createRouter} from 'vue-router';
import Overview from './pages/Overview.vue';

/*  
  The preferred use is a sub-router.
*/

export const routes = [
  {
    path: '/',
    name: 'business-manager',
    component: Overview,
    meta: {sidebar: true},
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
