var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { T } from '@dnx/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import businessApi from '../../app/api-generated/businesses';
import { mapActions, useStore } from 'vuex';
export const useBusinessManagerStore = defineStore('business-manager', () => {
    const businesses = ref([]);
    const store = useStore();
    const triggerAlertMessage = (payload) => store.dispatch('notifications/triggerAlertMessage', payload);
    const fetchBusinesses = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield businessApi.list();
        if (!response.success) {
            return;
        }
        businesses.value = response.value;
    });
    const publishBusiness = (business) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield (business.id ? businessApi.put(business.id, business) : businessApi.post(business));
        if (!response.success) {
            triggerAlertMessage({
                type: 'error',
                message: T(response.value[0].resourceKey),
            });
            return response;
        }
        fetchBusinesses();
        return response;
    });
    const deleteBusiness = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield businessApi.delete(id);
        if (!response.success) {
            triggerAlertMessage({
                type: 'error',
                message: T(response.value[0].resourceKey),
            });
            return response;
        }
        fetchBusinesses();
        return response;
    });
    return {
        fetchBusinesses,
        businesses: computed(() => businesses.value),
        publishBusiness,
        deleteBusiness,
        actions: () => mapActions({
            triggerAlertMessage: 'notifications/triggerAlertMessage',
        }),
    };
});
